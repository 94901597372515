/*
 * Bk infos
 */

.bk-info {
    @extend %bk-type;
    padding: 20px;
    background-color: $color-info;
    border: 1px solid $color-alert;
    border-radius: $global-radius;
    color: $black;

    &--warning {
        background-color: rgba($color-alert, .3);
        border: 1px solid $color-alert;
    }

    &--error {
        background-color: rgba($color-error, .3);
        border: 1px solid $color-error;
    }

    &--success {
        background-color: rgba($color-success, .3);
        border: 1px solid $color-success;
    }
}
