/*
 * Typography
 */


/* Default font */
html {
    font-family: $default-fonts;
    font-size: $root-font-size-ie11; /* IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
    line-height: 1.4;
}

body {
    font-family: $default-fonts;
    font-size: rem(16px);

    @include media('>=tablet') {
        font-size: $base-font-size;
    }
}

%headings {
    font-weight: 600;
    margin: rem($spacer-m) 0;
    line-height: 140%;
    color: $color-primary;
    font-family: $secondary-fonts;

    @include media('>=tablet') {
        margin: rem($spacer-m2) 0;
    }

    &:first-child {
        margin-top: 0 !important;
    }

    &.dark {
        color: $black;
    }

    &.grey {
        color: $color-text;
    }
}

h1,
.like-h1 {
    @extend %headings;
    font-size: rem(25px);
    margin-bottom: rem($spacer-m2);

    @include media('>=tablet') {
        margin-bottom: rem($spacer-xxl);
        font-size: rem(30px);
    }

    @include media('>=medium') {
        font-weight: 600;
        font-size: rem(40px);
    }
}

h2,
.like-h2,
%like-h2 {
    @extend %headings;
    font-size: rem(22px);
    margin-bottom: rem($spacer-m2);

    @include media('>=tablet') {
        margin-bottom: rem($spacer-xxl);
        font-size: rem(24px);
    }

    @include media('>=medium') {
        font-size: rem(32px);
    }
}

h3,
.like-h3,
%like-h3 {
    @extend %headings;
    font-size: rem(20px);

    @include media('>=tablet') {
        margin-bottom: rem($spacer-xxl);
        font-size: rem(22px);
    }

    @include media('>=medium') {
        font-size: rem(28px);
    }
}

h4,
.like-h4 ,
%like-h4 {
    @extend %headings;
    font-size: rem(18px);

    @include media('>=medium') {
        font-size: rem(22px);
    }
}

h5,
.like-h5 {
    @extend %headings;
    font-size: rem(16px);

    @include media('>=medium') {
        font-size: rem(18px);
    }
}

.subtitle {
    font-family: $default-fonts;
    font-size: rem(16px);
    font-weight: medium;

    @include media('>=tablet') {
        font-size: rem(20px);
    }

    @include media('>=medium') {
        font-size: rem(24px);
    }
}

a {
    display: inline-block;
    color: $color-primary;
    font-weight: 600;
    font-family: $secondary-fonts;
    text-decoration: underline;
    font-size: rem(14px);
    transition: .2s;

    @include media('>=medium') {
        font-size: rem(18px);
    }

    &:active {
        color: inherit;
    }

    p > & {
        display: inline;
    }

    &.no-u {
        text-decoration: none;
        font-size: rem(14px);
    }

}

@include dark-bg {
    a {
        color: $white;

        &:hover {
            color: $color-link-hover-dark-bg;
        }
    }
}

ul,
ol,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul {
    @extend %bullet-list;
    @include bk-type;
}

ol {
    @extend %ol-list;
    @include bk-type;
}

img {
    //width: auto;
    height: auto;
    max-width: 100%;
}

ul,
ol,
p,
fieldset {
    @include bk-type-txt;

    &:first-child {
        margin-top: 0;
    }
}

hr {
    border: 0;
    border-top: 1px solid $dark-grey;
    margin: rem($spacer-m) 0;
}

pre {
    display: block;
    border: 1px solid #690;
    margin: 0 0 1.5em;

    &[class*='language-'] {
        margin: 0 0 1.5em !important;
    }
}

fieldset {
    legend {
        @extend %like-h3;
    }
}

blockquote,
q {
    padding: 10px;
    margin: 0;
    font-style: italic;
    background-color: $white;
    border: 1px solid $dark-grey;

    &::before,
    &::after {
        content: '“';
        font-size: larger;
    }

    &::after {
        content: '”';
    }
}

.placeholder {
    color: $color-placeholder;

    .parsley-error & {
        color: $color-error;
    }
}

::-webkit-input-placeholder {
    color: $color-placeholder;

    .parsley-error & {
        color: $color-error;
    }
}

:-moz-placeholder {
    color: $color-placeholder;

    .parsley-error & {
        color: $color-error;
    }
}

::-moz-placeholder {
    color: $color-placeholder;

    .parsley-error & {
        color: $color-error;
    }
}

:-ms-input-placeholder {
    color: $color-placeholder;

    .parsley-error & {
        color: $color-error;
    }
}


// outline only on keyboard focus (with whatinput plugin)
a,
input,
select,
textarea,
button,
[tabindex] {
    &:focus {
        outline: none;

        [data-whatinput='keyboard'] & {
            @include outline;
        }
    }
}

@include dark-bg {
    color: $white;
}

::selection {
    background-color: $color-text-selection;
    color: $white;
}
