$c: '.header';


#{$c} {
    padding: rem(24px) 0;
    background-color: #fff !important;
    box-shadow: 0px 4px 8px rgba(102, 109, 146, .08);

    --bannerHeight: 0;
    --mobileBannerHeight:0;

    &__wrapper {
        position: sticky;
        top: var(--bannerHeight);
        left: 0;
        right: 0;
        z-index: 10;

        &:after {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: rem($spacer-m);
            pointer-events: none
        }
    }

    & > a {
        &:visited {
            color: inherit;
            background-color: inherit;
        }
    }

    .underlined {
        text-decoration: underline !important;

        @include media('>=medium') {
            margin-top: rem($spacer-s) !important;
        }

        a {
            text-decoration: underline !important;
        }
    }

    &__lk-level-1,
    &__lk-level-2 {
        z-index: 10;

        button,
        a {
            text-decoration: none;
            font-size: rem(16px);
            font-weight: 500;
            color: $color-text;
            font-family: $secondary-fonts;
            padding: rem($spacer-s) 0;
            background-color: transparent;
            border: 0;
            width: 100%;
            text-align: left;
            position: relative;

            &:after {
                @include icomoon;
                content: $icon-arrow-b;
                z-index: 1;
                position: absolute;
                left: auto;
                right: rem($spacer-xxs);
                top: 50%;
                transform: translateY(-50%) rotate(270deg);
                font-size: rem($spacer-m2);
                color: $color-primary;

                @include media('>=medium') {
                    display: none;
                }
            }

            @include media('>=medium') {
                font-size: rem(14px);
                padding: rem($spacer-xxs) 0;
            }

            &.big {
                color: $color-text;

                &:after {
                    transform: translateY(-50%) rotate(0deg);
                }

                @include media('>=medium') {
                    font-family: $secondary-fonts;
                    margin-bottom: rem($spacer-xxs);
                    color: $blue;
                    font-size: rem(16px);
                    font-weight: 600;
                }

                &--blue {
                    font-weight: 600;
                    color: $color-primary;

                    &:after {
                        transform: translateY(-50%) rotate(180deg);
                    }
                }
            }

            &:hover,
            &:active {
                color: $color-primary;
            }
        }

        a {
            &:after {
                // transform: translateY(-50%) rotate(270deg);
                display: none;
            }
        }
    }

    &__lk-level-3 {
        padding: rem(14px) 0;
        border-bottom: 1px solid $light-grey;

        @include media('>=medium') {
            border-bottom: 0;
            padding: 0 0 rem(10px) 0;
        }

        button,
        a {
            font-family: $default-fonts;
            font-size: rem(16px);
            font-weight: 500;
            color: $color-text;

            @include media('>=tablet') {
                font-size: rem(14px);
            }
        }

    }

    .wrapper-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: rem(20px);

        h1 {
            margin-bottom: 0;
        }

        @include media('>=tablet') {
            height: rem(40px);
        }

        @include media('>=medium') {
            justify-content: flex-start;
        }
    }

    .logo {
        display: block;
        height: 100%;

        @include media('>=medium') {
            max-width: rem(150px);
            height: auto;
        }

        img {
            display: block;
            height: 100%;
            max-width: rem(55px);

            @include media('>=medium') {
                max-width: rem(69px);
                height: auto;
            }
        }
    }

    &__right-btns {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: rem($spacer-m);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        background-color: $white;

        @include media('>=medium') {
            position: relative;
            padding: 0;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        & > * {
            margin-left: rem($spacer-xxs);
        }

        .btn {
            font-size: rem(14px);
            width: 100%;
            padding: rem(14px) rem(42px);
            font-weight: 600;
            white-space: nowrap;
            margin-bottom: rem($spacer-xs);

            @include media('>=tablet') {
                max-width: rem(150px);
                min-width: 0;
                margin-bottom: 0;
            }
        }

        .language-switcher {
            position: relative;

            &__top {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                font-weight: 700;
                background-color: transparent;
                border: 0;
                text-transform: uppercase;
                color: $color-primary;
                font-size: rem(14px);

                span {
                    margin-right: rem($spacer-xs);
                    font-size: rem(30px);
                    transform: rotate(0deg);
                }

                &[aria-expanded='true'] {
                    span {
                        transform: rotate(180deg);
                    }
                }
            }

            &__bottom {
                display: none;
                position: absolute;
                top: calc(100% + $spacer-l);
                left: 0;
                width: auto;
                height: auto;
                padding: rem($spacer-m2) rem(40px);
                background-color: $white;
                border-radius: rem(12px);
                border: 1px solid $light-grey;

                &:before {
                    content: '';
                    position: absolute;
                    top: rem(-$spacer-l);
                    bottom: 100%;
                    left: 0;
                    right: 0;
                }

                &--visible {
                    display: block !important;
                }
            }
        }

        &--mobile {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            @include media('>=medium') {
                display: none;
            }

            &:before {
                @include icomoon;
                content: $icon-arrow-b;
                font-size: rem(24px);
            }

            &:hover,
            &:active {
                .wpml-ls-sub-menu {
                    display: block;
                    margin-top: 0 !important;
                }
            }

            .wpml-ls-sub-menu {
                display: none;
                margin-top: 0;
            }
        }
    }

    &__main-nav {
        display: none;
        position: absolute;
        overflow-y: scroll;
        top: calc(rem(67px) + var(--mobileBannerHeight));
        left: 0;
        height: calc(100vh - 165px);
        padding: rem(20px) rem(24px) rem(40px);
        background: $white;
        z-index: 2;
        margin: 0 auto;
        width: 100%;

        @include media('>=tablet') {
            padding: rem(20px);
        }

        @include media('>=medium') {
            background: transparent;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            position: relative;
            overflow: inherit;
            top: auto;
            left: auto;
            width: 100%;
            height: auto;
            padding: 0;
            margin: 0 rem(40px);
        }
    }

    &__ul-wrapper {
        overflow-y: scroll;
        padding-bottom: rem(165px);

        @include media('>=tablet') {
            padding-bottom: 0;
            overflow-y: visible;
        }

        > ul {
            @extend %no-styled-list;
            text-align: center;

            @include media('>=medium') {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            #{$c}__li-level-1 {
                position: relative;
                padding: 0;
                // padding: rem(14px) 0;
                text-align: left;
                border-bottom: 1px solid $light-grey;

                @include media('>=medium') {
                    text-align: left;
                    padding: 0 rem(20px);
                    border-bottom: 0;
                }

                #{$c}__lk-level-1 {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }

                .btn-expand {
                    transform: rotate(-90deg);
                    border: none;
                    padding: 0;
                    transition: ease .3s;
                    transform-origin: center;
                    background-color: transparent;
                    font-size: 25px;

                    @include media('>=medium') {
                        display: none;
                    }
                }


                #{$c}__level-2-wrapper {
                    position: fixed;
                    overflow: auto;
                    top: calc(rem(60px) + var(--mobileBannerHeight));
                    left: 100%;
                    width: 100%;
                    height: calc(100% - 165px);
                    padding: 0 rem(20px);
                    padding-left: 0;
                    transition: ease .3s;
                    background-color: $white;
                    z-index: 2;

                    @include media('>=medium') {
                        display: none;
                        position: absolute;
                        top: calc(100% + $spacer-l);
                        left: 0;
                        width: auto;
                        height: auto;
                        padding: rem($spacer-m2) rem(40px);
                        background-color: $white;
                        border-radius: rem(12px);
                        border: 1px solid $light-grey;

                        &:before {
                            content: '';
                            position: absolute;
                            top: rem(-$spacer-l);
                            bottom: 100%;
                            left: 0;
                            right: 0;
                        }
                    }

                    &--visible {
                        left: 0;

                        @include media('>=medium') {
                            overflow: visible;
                            display: block;
                        }
                    }

                    #{$c}__list-links {
                        @extend %no-styled-list;
                        margin-bottom: rem($spacer-m);

                        @include media('>=medium') {
                            margin-bottom: 0;
                        }

                        #{$c}__li-level-2 {
                            padding: 0 0 0 rem(20px);
                            margin: 0;
                            text-align: left;
                            white-space: nowrap;

                            &:last-of-type {
                                margin-bottom: 0;
                            }

                            @include media('>=medium') {
                                // margin: 0;
                                // margin-bottom: rem($spacer-xs);
                                border-bottom: 0;
                                padding: 0;
                            }

                            #{$c}__lk-level-2 {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;
                            }

                            .btn-expand {
                                transform: rotate(0deg);
                            }

                            &__back {
                                border-bottom: 0;
                                margin: rem($spacer-m) 0 0 rem(-10px);

                                @include media('>=medium') {
                                    display: none;
                                }

                                .btn-back {
                                    text-decoration: none;
                                    font-size: rem(14px);
                                    font-weight: 500;
                                    color: $color-text;
                                    border: 0;
                                    background: transparent;
                                    font-family: $secondary-fonts;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: flex-start;
                                    padding-left: 0;
                                    padding-bottom: rem(4px);

                                    [class^='icon'] {
                                        display: inline-block;
                                        vertical-align: baseline;
                                        transform: rotate(90deg);
                                        margin-right: rem(10px);
                                        color: $blue;
                                        font-size: rem(25px);
                                    }
                                }
                            }

                            a {
                                text-decoration: none;
                            }
                        }

                        &--ext {
                            position: relative;
                            display: block;
                            overflow: auto;
                            // margin-top: rem($spacer-s);
                            padding: 0;
                            transition: ease .3s;
                            z-index: 2;

                            @include media('>=medium') {
                                margin-left: rem(-$spacer-m);
                                padding: 0 rem($spacer-m);
                                width: 100vw;
                                background: $blue03;
                            }

                            ul {
                                margin-bottom: rem($spacer-m);

                                @include media('>=medium') {
                                    margin: 0 !important;
                                }
                            }

                            #{$c}__li-level-2 {
                                margin: 0 !important;

                                @include media('>=medium') {
                                    margin: 0 !important;
                                    margin-bottom: rem($spacer-xs) !important;
                                }

                                &:last-of-type {
                                    @include media('>=medium') {
                                        margin-bottom: 0 !important;
                                    }
                                }
                            }

                            @include media('>=medium') {
                                position: absolute;
                                top: -1px;
                                left: 95%;
                                width: auto;
                                height: auto;
                                padding: rem($spacer-m2) rem(40px);
                                border-top-right-radius: rem(12px);
                                border-bottom-right-radius: rem(12px);
                                border: 1px solid $light-grey;
                                border-left: 0;
                                transition: ease .3s;
                                pointer-events: all;
                                margin-top: 0;
                                margin-left: 0;
                            }
                        }
                    }
                }

                #{$c}__level-3-wrapper {
                    display: none;

                    @include media('>=medium') {
                        display: block;
                    }

                    &--visible {
                        display: block;
                        position: relative;

                        // &:before,
                        // &:after {
                        //     content: '';
                        //     position: absolute;
                        //     top: 0;
                        //     left: -20px;
                        //     right: 0;
                        //     bottom: 0;
                        //     z-index: -1;
                        //     background-color: $blue03;
                        // }

                        // &:after {
                        //     left: 0;
                        //     right: -20px;
                        // }
                    }
                }
            }
        }
    }
}

