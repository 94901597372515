$a: '.bk-hero-banner';

#{$a} {
    &__text {
        padding-top: rem($spacer-m);
    }

    @include media('>medium') {
        display: flex;
        background-color: $grey;

        &__img {
            width: 100%;
            flex-shrink: 0;
        }

        &__text {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-top: 0;
            width: 100%;
            flex-shrink: 0;
            transform: translateX(-100%);
            color: $white;
            background-color: rgba($black, .3);
        }

        &__title {
            color: inherit;
        }

        &__desc {
            font-size: rem(20px);
        }
    }
}
