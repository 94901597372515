/* ------------------------------------------------
- Ici, toutes les fonctions sass et les mixins
------------------------------------------------ */

/* ============================== */
/* Custom Functions */
/* ============================== */

/// Convert px to rem
/// @param  {px value} $px-value - Taille en pixel
/// @param  {px value} $baseline [$root-font-size] - Taille de référence
@function rem($px-value, $baseline: $root-font-size) {
    @if $px-value == 0 or type-of($px-value) != 'number' {
        @return $px-value;
    }

    @return calc($px-value / (calc($baseline / 1rem)));
}

/// Convert px to rem
/// @param  {px value} $px-value - Taille en pixel
/// @param  {px value} $baseline [$root-font-size] - Taille de typo du parent
@function em($px-value, $baseline: $root-font-size) {
    @if $px-value == 0 or type-of($px-value) != 'number' {
        @return $px-value;
    }

    @return $px-value / ($baseline / 1em);
}

@mixin font-smoothing() {
    /* stylelint-disable-next-line property-no-unknown */
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-stroke: 1px transparent;
}

/// Permet à un wrapper de contenir des éléments en float ou avec des marges
@mixin clearfix {
    //*zoom: 1;
    &::before,
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

/// Génération d'une font-face
/// @group typography
/// @param  {keyword} $name - nom de la webfont
/// @param  {keyword} $filename - nom du fichier webfont
/// @param  {integer | keyword} $font-weight [400] - font-weight
/// @param  {keyword} $font-style - font-style
@mixin font-face($name, $filename, $font-weight: 400, $font-style: normal) {
    $path: '../fonts';

    @font-face {
        font-family: #{$name};
        src: url('#{$path}/#{$name}/#{$filename}.woff2') format('woff2'),
            url('#{$path}/#{$name}/#{$filename}.woff') format('woff');
        font-style: #{$font-style};
        font-weight: #{$font-weight}; /* stylelint-disable-line no-extra-semicolons */
    }
};

//For @extend
.visually-hidden {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    position: absolute;
}

@mixin outline ($width: 2px, $style: solid, $color: $color-focus, $outline-offset: 2px) {
    outline: $width $style $color;
    outline-offset: $outline-offset;
}

/// Pas de marge basse sur le dernier enfant direct
/// @group spacing
@mixin last-child-no-margin {
    > *:last-child {
        margin-bottom: 0
    }
}

/// Pour couper les mots proprement
/// @group typography
/// @link https://css-tricks.com/almanac/properties/w/word-break/
@mixin break-word {
    -ms-word-break: break-all;
    word-break: break-all;

    /* Non standard for WebKit */
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

/// Contenu centré en largeur
/// @group layout
@mixin site-content {
    max-width: rem($sc-max-width);
    margin: 0 auto;
    padding: 0 rem($sc-x-padding);

    @include media('<medium') {
        padding: 0 rem($sc-x-padding-s);
    }

    .header & {
        max-width: rem(1315px);
    }

    &.pb-0 {
        padding-bottom: 0 !important;
    }
}

@mixin bk-type ($margin-b: $spacer-m2) {
    @include last-child-no-margin;
    margin-bottom: rem($margin-b);
}

@mixin bk-type-txt ($margin-t: $spacer-m) {
    @include last-child-no-margin;
    margin-top: rem($margin-t);
}

/// @group typography
@mixin icomoon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/// Ce mixin est utilisé pour la propriété object fit, avec le pollyfill object-fit-images
/// @link https://github.com/fregante/object-fit-images
/// @param  {keyword} $fit - contain ou Cover
/// @param  {keyword} $position [null] - Position
@mixin object-fit($fit: fill, $position: null) {
    -o-object-fit: $fit;
    object-fit: $fit;

    @if $position {
        -o-object-position: $position;
        object-position: $position;
        font-family: 'object-fit: #{$fit}; object-position: #{$position}';
    }

    @else {
        font-family: 'object-fit: #{$fit}';
    }
}

/// Etend le layout jusqu'au bord de l'écran (à ajuster avec le js car ne prend pas en compte la barre de scroll)
/// @group layout
@mixin full-width {
    position: relative;
    left: 50%;
    width: 100vw;
    max-width: none;
    transform: translateX(-50%);
}

.full-width {
    @include full-width;
}

@mixin card-box-shadow($width: 10px, $opacity: .2) {
    box-shadow: 0 0 rem($width) rgba($black, $opacity);
}

@mixin dark-bg() {
    #{$dark-backgrounds} {
        @media not print {
            @content
        }
    }
}

/// Apparence de la barre de scroll
/// @group layout
/// @param  {value in px} $size - taille de la barre
@mixin scrollbar($size) {
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
        // background-color: rgba(0,0,0,0);
        border-radius: 100px;
    }

    &::-webkit-scrollbar:hover {
        background-color: rgba(0, 0, 0, .09);
    }

    &::-webkit-scrollbar-track {
        background-color: $blue03;
    }

    &::-webkit-scrollbar-thumb {
        background: $blue;
        background-color: $blue;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        // background: rgba(0, 0, 0, .5);
        border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb:active {
        background: rgba(0, 0, 0, .61); /* Some darker color when you click it */
        border-radius: 100px;
    }

    /* add vertical min-height & horizontal min-width */
    &::-webkit-scrollbar-thumb:vertical {
        min-height: $size;
    }

    &::-webkit-scrollbar-thumb:horizontal {
        min-width: $size;
    }
}
