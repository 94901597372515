.banner-info {
    position: relative;
    padding: .7rem 0;
    font-size: 1.4rem;
    width: 100%;
    z-index: 99999999;

    &.hide {
        display: none !important;
    }

    &.bg-coral {
        background-color: #ff5657;

        .wyg {
            color: $white;
        }
    }

    &.bg-orange {
        background-color: #c34728;

        .wyg {
            color: $white;
        }
    }

    &.bg-light-orange {
        background-color: #ff8f73;

        .wyg {
            color: $black;
        }
    }

    @include media('<tablet') {
        padding: 1.4rem 0;
    }

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include media('>=tablet') {
            align-items: center;
        }
    }

    .wyg {
        margin: 1.1rem 0;
        font-family: Montserrat, sans-serif;

        @include media('<tablet') {
            margin: 0;
            padding-right: 10px;
            font-size: 12px;
        }
    }

    &--announcement {
        text-align: center;
    }

    &--actions {
        flex: auto 0 1;
        display: flex;
        flex-direction: column;
        gap: .8rem;

        .btn {
            font-size: 1.4rem;
            background-color: white;
            color: #000FFF;

            @include media('<tablet') {
                font-size: 1rem;
            }

            @include media('>=tablet') {
                white-space: nowrap;
            }

        }

        @include media('<desktop') {
            padding-right: 2rem;
        }

        @include media('<tablet') {
            padding: 0;
            flex-direction: row;

            .btn {
                width: calc(50% - .4rem);

                &:first-child {
                    &:last-child {
                        width: 100%;
                    }
                }
            }
        }
    }

    .close {
        position: absolute;
        right: .5rem;
        top: 50%;
        color: inherit;
        border: 0;
        background-color: transparent;
        font-size: 2.4rem;
        cursor: pointer;
        transform: translateY(-50%);
        padding: 10px;

        * {
            pointer-events: none;
        }

        @include media('<tablet') {
            right: 0;
            top: 0;
            transform: translateY(0);
            font-size: 1.4rem;
        }
    }

    @include media('<tablet') {
        .row {
            flex-wrap: wrap;
            gap: 1.2rem;

            .col-mobile {
                flex: calc(50% - .6rem) 0 0;

                .wyg * {
                    text-align: left;
                }

                &:nth-child(2) {
                    padding-right: 20px;
                }
            }

            .banner-info--actions {
                flex: 100% 0 0;
            }
        }
    }
}
