$d: '.btn-menu';

#{$d} {
    background: none;
    border: 0;
    margin-left: rem($spacer-xs);
    padding: rem($spacer-xxs);

    @include media('>=medium') {
        display: none;
    }

    &__active {
        #{$d}__hamburger-inner {
            transition-delay: .12s;
            transition-timing-function: cubic-bezier(.215,.61,.355,1);
            transform: rotate(45deg);

            &:before {
                top: 0;
                transition: top 75ms ease,opacity 75ms ease .12s;
                opacity: 0;
            }

            &:after {
                bottom: 0;
                transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
                transform: rotate(-90deg);
            }
        }
    }

    &__hamburger {
        display: block;
        overflow: visible;
        height: 12px;
        margin: 0;
        cursor: pointer;
        transition-timing-function: linear;
        transition-duration: .15s;
        transition-property: opacity,filter;
        text-transform: none;
        color: inherit;
        border: 0;
        background-color: transparent;
    }

    &__hamburger-box {
        position: relative;
        display: inline-block;
        width: 16px;
        height: 12px;
    }

    &__hamburger-inner {
        position: absolute;
        display: block;
        top: 50%;
        margin-top: -2px;
        width: 16px;
        height: 2px;
        transition-property: transform;
        background-color: $color-primary;
        transition-timing-function: cubic-bezier(.55,.055,.675,.19);
        transition-duration: 75ms;
        border-radius: 5px;

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 16px;
            height: 2px;
            top: -6px;
            background-color: $color-primary;
            transition: top 75ms ease .12s, opacity 75ms ease;
            transition-timing-function: ease;
            transition-duration: .15s;
            transition-property: transform;
            border-radius: 5px;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 16px;
            height: 2px;
            bottom: -6px;
            background-color: $color-primary;
            transition: bottom 75ms ease .12s, transform 75ms cubic-bezier(.55, .055, .675, .19);
            transition-timing-function: ease;
            transition-duration: .15s;
            transition-property: transform;
            border-radius: 5px;
        }
    }
}
