@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?6mp28e') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?6mp28e') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?6mp28e##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checked-comp {
  &:before {
    content: $icon-checked-comp; 
  }
}
.icon-arrow-l {
  &:before {
    content: $icon-arrow-l; 
  }
}
.icon-arrow-r {
  &:before {
    content: $icon-arrow-r; 
  }
}
.icon-arrow-b {
  &:before {
    content: $icon-arrow-b; 
  }
}
.icon-arrow-t {
  &:before {
    content: $icon-arrow-t; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-applogo {
  &:before {
    content: $icon-applogo; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-document {
  &:before {
    content: $icon-document; 
  }
}
.icon-player {
  &:before {
    content: $icon-player; 
  }
}
.icon-player-full {
  &:before {
    content: $icon-player-full; 
  }
}
.icon-checked {
  &:before {
    content: $icon-checked; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-arrow-first {
  &:before {
    content: $icon-arrow-first; 
  }
}
.icon-arrow-last {
  &:before {
    content: $icon-arrow-last; 
  }
}
.icon-arrow-line-b {
  &:before {
    content: $icon-arrow-line-b; 
  }
}
.icon-arrow-line-l {
  &:before {
    content: $icon-arrow-line-l; 
  }
}
.icon-arrow-line-r {
  &:before {
    content: $icon-arrow-line-r; 
  }
}
.icon-arrow-line-t {
  &:before {
    content: $icon-arrow-line-t; 
  }
}
.icon-play-circle-line {
  &:before {
    content: $icon-play-circle-line; 
  }
}
.icon-rss {
  &:before {
    content: $icon-rss; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}

