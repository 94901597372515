$c: '.btn-submenu-panel';

#{$c} {
    background: none;
    border: none;
    color: $grey;

    &__search {
        margin-left: auto;
    }

    [class^='icon'] {
        font-size: 26px;
    }
}

$d: '.submenu-panel';

#{$d} {
    display: none;
    position: absolute;
    top: rem(60px);
    left: 0;
    width: 100%;
    padding: rem(40px) 0;
    background: $grey;

    form {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button[type='submit'] {
            margin-left: rem($spacer-s);
        }
    }

    &__close {
        position: absolute;
        top: rem(10px);
        right: rem(10px);
        background: none;
        border: none;
    }
}
