/* ------------------------------------------------
- Ici, tous les styles relatifs à la structure de la page. La base, la 'coquille' du site.
------------------------------------------------ */

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body {
    position: relative;
    height: 100%;
}

body {
    @include scrollbar(6px);
    //background à remplir pour l'accessibilité
    background-color: $white;
    min-width: 320px;

    color: $color-text;
    overflow-x: hidden;
}

.site-content {
    @include site-content;
}

.skip-links {
    @extend %no-styled-list;
    margin: 0;

    li {
        margin: 0;
        padding: 0;
    }

    a {
        position: absolute;
        left: -50000px;

        &:focus {
            z-index: 100;
            position: static;
            left: auto;
        }
    }
}


/* ============================== */
/* CONTENT */
/* ============================== */
.page-content-wrapper {
    @include clearfix;

    .site-content {
        &.-main {
            @include last-child-no-margin;
            padding-top: rem($spacer-xl);
            padding-bottom: rem($spacer-xl);

            @include media('<medium') {
                padding-top: rem(-$spacer-l);
                padding-bottom: rem(-$spacer-l);
            }

            > * {
                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}
