$d: '.breadcrumb';

#{$d} {
    @extend %no-styled-list;
    position: relative;

    &-chevron {
        &:after {
            @include icomoon;
            content: $icon-arrow-l;
            margin: 0 $spacer-xxs rem(4px);
            display: inline-block !important;
            vertical-align: middle;
            font-size: rem(35px);
            transform: rotate(180deg);
            color: $grey;
        }
    }

    &_last {
        text-decoration: none !important;
        font-size: rem(18px) !important;
        font-family: $default-fonts !important;
        font-weight: 500 !important;
        color: $dark-grey !important;

        @include media('>=tablet') {
            font-size: rem(24px) !important;
        }
    }

    &__list {
        display: block;

        span {
            a {
                text-decoration: none;
                font-size: rem(18px);
                font-family: $default-fonts;
                font-weight: 500;
                color: $grey;

                @include media('>=tablet') {
                    font-size: rem(24px);
                }
            }
        }

        li {
            display: inline;
            position: relative;
            vertical-align: middle;

            a {
                display: inline;
                vertical-align: middle;
                line-height: normal;
                text-decoration: none;
                font-size: rem(18px);
                font-family: $default-fonts;
                font-weight: 500;
                color: $grey;

                @include media('>=tablet') {
                    font-size: rem(24px);
                }
            }

            &:not(:last-of-type) {
                &::after {
                    @include icomoon;
                    content: $icon-arrow-l;
                    margin: 0 $spacer-xxs;
                    display: inline-block !important;
                    vertical-align: middle;
                    font-size: rem(35px);
                    transform: rotate(180deg);
                    color: $grey;
                }
            }

            &:last-of-type {
                a {
                    color: $color-text !important;
                }
            }
        }
    }
}
