/* ------------------------------------------------
- Ici, les helpers
------------------------------------------------ */
/**
 * Automatically generate text color utilities class for each of our
 * defined contrib colors.
 *
 * <div class="color--green"></div>
 */

@if (variable-exists(contrib-colors)) {
    @each $color-name, $color-value in $contrib-colors {
        .color--#{$color-name} {
            color: $color-value !important;
        }
    }
}

/**
* Automatically generate background-color utilities class for each of our
 * defined contrib colors.
 *
 * <div class="bg--green"></div>
 */

@if (variable-exists(contrib-colors)) {
    @each $color-name, $color-value in $contrib-colors {
        .bg--#{$color-name} {
            background-color: $color-value !important;
        }
    }
}

.white-color {
    color: $white;
}

.last-child-no-margin {
    @include last-child-no-margin;
}

.bk-type-txt {
    @include bk-type-txt;
}

.bk-type,
%bk-type {
    @include bk-type;
}

.bk-type-m {
    @include bk-type(m);
}

.bk-type-l {
    @include bk-type(l);
}

.pt-0 {
    padding-top: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-m {
    margin-top: rem($spacer-m) !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-xxs {
    margin-bottom: rem($spacer-xxs) !important;
}

.mb-m {
    margin-bottom: rem($spacer-m) !important;
}

.mb-l {
    margin-bottom: rem($spacer-l) !important;
}

.mb-xl {
    margin-bottom: rem($spacer-xl) !important;
}

@if (variable-exists(spacers)) {
    @each $spacer-name, $spacer-value in $spacers {
        .mb-#{$spacer-name} {
            margin-bottom: $spacer-value !important;
        }
    }
}

.big-text {
    font-size: rem(24px);
}

.ta-l {
    text-align: left !important;
}

.ta-r {
    text-align: right !important;
}

.ta-c {
    text-align: center;
}

.float-l {
    float: left;
}

.float-r {
    float: right;
}

.uppercase {
    text-transform: uppercase;
}

.fw-bold {
    font-weight: bold;
}

.fw-normal {
    font-weight: 400 !important;
}

.ws-nowrap {
    white-space: nowrap;
}

.out,
%out {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
    width: 0;
}

.mob-hidden {
    @include media('<desktop') {
        display: none !important;
    }
}

.mob-visible {
    display: none;

    @include media('<desktop') {
        display: block;
    }
}

.mob-block {
    @include media('<desktop') {
        display: block;
    }
}

.lk-no-underline {
    text-decoration: none;

    a {
        text-decoration: none;
    }
}

.text-info {
    padding: 5px 10px;
    background-color: $color-info;
    border: 1px solid $color-alert;
    color: $black;
}

.text-error {
    color: $color-error;
}

.text-success {
    color: $color-success;
}

.bullet-list,
%bullet-list {
    text-align: left;

    li {
        margin-bottom: rem($spacer-xxs);
        padding-left: rem($spacer-s);
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: rem($spacer-xs);
            transform: translate(0, -50%);
            width: rem(6px);
            height: rem(6px);
            color: $yellow;
            // color: $color-primary;
            background-color: currentColor;
            border-radius: 50%;
        }

        &.checked {
            &:before {
                // @include icomoon;
                // content: $icon-checked;
                content: 'v';
                background-color: transparent;
                top: 0;
                transform: translate(0);
            }
        }

        ul {
            li {
                &:before {
                    width: rem(4px);
                    height: rem(4px);
                    top: rem(10px);
                }
            }
        }
    }
}

@include dark-bg {
    .bullet-list {
        li {
            &:before {
                color: inherit;
            }
        }
    }
}

.blue-list {
    li {
        &::before {
            color: $blue;
        }

        a {
            text-decoration: underline;
            font-weight: 400;
            font-size: rem(18px);
            color: $color-text;
        }
    }
}

.ol-list,
%ol-list {
    text-align: left;
    counter-reset: ol-counter;

    >li {
        margin: 0 0 rem($spacer-xxs) rem($spacer-xs);
        padding-left: rem($spacer-m);
        position: relative;

        &:before {
            content: counter(ol-counter)'.';
            counter-increment: ol-counter;
            position: absolute;
            right: 98%;
            top: 0;
            color: $color-primary;
            display: inline-block;
            padding-right: rem(3px);
            font-weight: bold;
        }
    }

    ul,
    ol {
        margin-bottom: rem($spacer-xs);

        li {
            font-size: rem(14px);
        }
    }

    ol {
        counter-reset: ol-counter-bis;

        li {
            counter-increment: ol-counter-bis;

            &:before {
                content: counter(ol-counter)'.'counter(ol-counter-bis)'.';
            }
        }
    }
}

.square-list {
    li {
        padding-left: rem(15px);
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: rem(8px);
            width: rem(6px);
            border-top: 6px solid currentColor;
        }

        li {
            padding-left: rem(10px);

            &::before {
                width: rem(3px);
                border-width: rem(3px);
            }
        }
    }
}

.bullet-list,
.square-list {
    &.-secondary {
        li {
            &::before {
                color: $color-secondary;
            }
        }
    }
}

.inline-list {
    letter-spacing: -.31em;

    > li {
        display: inline-block;
        margin: 0 rem($spacer-xs) rem($spacer-xs) 0;
        vertical-align: middle;
        letter-spacing: normal;

        &:last-child {
            margin-right: 0;
        }
    }
}

.no-styled-list,
%no-styled-list {
    margin-bottom: 0;

    li {
        margin: 0;
        padding: 0;
        position: static;

        &:before,
        &:after {
            display: none;
        }
    }
}

.of-cover {
    @include object-fit(cover);
}

.of-contain {
    @include object-fit(contain);
}


.hidden {
    display: none !important;
}

.hidden-from--desktop {
    @include media('>=desktop') {
        display: none !important;
    }
}

.hidden-from--medium {
    @include media('>=medium') {
        display: none !important;
    }
}

.hidden-from--tablet {
    @include media('>=tablet') {
        display: none !important;
    }
}

.off-mobile-container {
    @include media('>=tablet') {
        display: none !important;
    }
}

.hidden-from--mobile {
    @include media('>=mobile') {
        display: none !important;
    }
}


.hidden-until--desktop {
    @include media('<desktop') {
        display: none !important;
    }
}

.hidden-until--medium {
    @include media('<medium') {
        display: none !important;
    }
}

.hidden-until--tablet {
    @include media('<tablet') {
        display: none !important;
    }
}

.hidden-until--mobile {
    @include media('<mobile') {
        display: none !important;
    }
}

.wysiwyg-content {
    >* {
        &:first-child {
            margin-top: 0;
            padding-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
}

.paragraph {
    max-width: rem($sc-max-width);
    margin: rem($spacer-xxl) auto;
    padding: 0 rem($sc-x-padding);

    &.bg--blue {
        background-color: $blue03;
    }

    .site-content & {
        max-width: none;
        padding: 0;
    }

    .mt-xl {
        margin-top: rem(90px) !important;
    }

    &-full-width {
        position: relative;
        left: 50%;
        width: 100vw;
        max-width: none;
        margin: rem($spacer-xxl) auto;
        transform: translateX(-50%);

        &.bg--blue {
            background-color: $blue03;
            padding: rem($spacer-m2) rem($sc-x-padding-s);

            @include media('>=tablet') {
                margin-top: rem(-$spacer-m2);
            }
        }

        & > .paragraph {
            padding: 0;
            margin: 0;
        }

        & > h1,
        & > h2,
        & > h3 {
            &:first-of-type {
                margin-left: auto;
                margin-right: auto;
                max-width: rem($sc-max-width);
            }
        }
    }

    &-small {
        max-width: rem(816px); // 800px + padding

        & > .entry-content {
            a {
                color: inherit;
                font-size: inherit;
                font-family: inherit;
                font-weight: inherit;
            }
        }
    }

    &-from-tablet {
        position: relative;
        left: 50%;
        width: 100vw;
        max-width: none;
        transform: translateX(-50%);
        margin: rem($spacer-m2) auto;

        @include media('>=tablet') {
            max-width: rem($sc-max-width);
            margin: rem($spacer-xxl) auto;
            padding: 0 rem($sc-x-padding);
            transform: none;
            left: auto;
            width: 100%;
        }
    }

    @include media('<medium') {
        padding: 0 rem($sc-x-padding-s);
    }

    @include media('<tablet') {
        margin: rem($spacer-m2) auto;
    }
}

.reduced-content {
    max-width: rem(795px) !important;
    margin-left: auto;
    margin-right: auto;

    &-xs {
        max-width: rem(480px) !important;
    }
}

.shadow {
    &-s {
        box-shadow: 0px 4px 8px 0px rgba(102,109,146,.08);
    }

    &-m {
        box-shadow: 0px 12px 24px 0px rgba(102,109,146,.1);
    }
}

.separator {
    border-bottom: 2px solid $yellow;
    width: rem($spacer-xxl);
    margin-bottom: rem($spacer-xs);

    &--large {
        border-bottom: 4px solid $yellow;
        width: rem(128px);
        margin-bottom: rem($spacer-s);
    }
}

.trc {
    background: linear-gradient(45deg, transparent 95%, $yellow 5%);
}

.bk-edito {
    h2,
    h3 {
        margin-bottom: rem($spacer-xxs) !important;

        @include media('>=medium') {
            margin-bottom: rem($spacer-m) !important;
        }
    }

    p {
        margin-bottom: rem($spacer-s) !important;

        a {
            display: inline;
        }
    }

    table {
        position: relative;
        width: 100%;

        .site-content & {
            padding: 0;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 1px solid $blue20;
            border-radius: rem(12px);
            z-index: -1;
        }

        tbody {
            tr {
                margin: rem(40px);
                margin-bottom: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;

                &:not(:last-of-type) {
                    border-bottom: 1px solid $blue20;
                }

                @include media('>=tablet') {
                    flex-direction: row;
                }
            }

            td {
                width: 100%;

                h5 {
                    margin-bottom: rem($spacer-xxs);
                    color: $color-text;
                }

                p {
                    max-width: rem(225px);
                    font-size: rem(16px);
                    margin-top: 0;

                    &:last-of-type {
                        margin-bottom: rem($spacer-m);
                    }
                }

                ul {
                    margin-top: 0;

                    &:last-of-type {
                        margin-bottom: rem($spacer-m);
                    }

                    li {
                        font-size: rem(16px);

                        &::before {
                            color: $color-text;
                        }
                    }
                }
            }
        }
    }
}

.body-small,
%body-small {
    color: $color-text;
    font-weight: 400;
    font-family: $default-fonts;
    font-size: rem(14px);

    @include media('>=tablet') {
        font-size: rem(16px);
    }
}

.body-medium,
%body-medium {
    color: $color-text;
    font-weight: 400;
    font-family: $default-fonts;
    font-size: rem(16px);

    @include media('>=tablet') {
        font-size: rem(18px);
    }
}

.body-large,
%body-large {
    color: $color-text;
    font-weight: 400;
    font-family: $default-fonts;
    font-size: rem(18px);

    @include media('>=tablet') {
        font-size: rem(20px);
    }
}

.body-xlarge,
%body-xlarge {
    color: $color-text;
    font-weight: 400;
    font-family: $default-fonts;
    font-size: rem(16px);

    @include media('>=tablet') {
        font-size: rem(20px);
    }
}

.body-xlarge--plus,
%body-xlarge--plus {
    color: $color-primary;
    font-weight: 600;
    font-family: $secondary-fonts;
    font-size: rem(16px);

    @include media('>=tablet') {
        font-size: rem(22px);
    }
}

.bk-nl {
    padding: rem($spacer-m);

    @include media('>=tablet') {
        padding: rem($spacer-xxl);
    }

    .ta-c {
        max-width: rem(600px);
        margin: 0 auto;
    }
}

/* stylelint-disable selector-class-patern */
.search {
    &_blog {
        margin: rem(40px) auto 0;
        max-width: rem(796px);
    }
}
/* stylelint-enable selector-class-patern */

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
